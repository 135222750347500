import { createContentfulRequest, backendRequest } from '@oresundsbron/api';
import {
  createClient as CreateClient,
  dedupExchange,
  fetchExchange,
  cacheExchange,
} from '@urql/core';
import { retryExchange } from '@urql/exchange-retry';

import axios from 'axios';
import { pipe } from 'fp-ts/lib/function';
import { map, of } from 'fp-ts/lib/TaskEither';
import { Do, apS } from 'fp-ts/Identity';

const createClient = () =>
  CreateClient({
    url: `${process.env.API_URL}/api/content/v1/contentful/graphql`,
    exchanges: [
      dedupExchange,
      cacheExchange,
      retryExchange({
        retryWith: (error, operation) => {
          if (
            error.networkError &&
            error.response &&
            error.response.status === 429
          ) {
            const delay = +(
              error.response.headers.get('X-Contentful-RateLimit-Reset') || 1
            );

            console.warn(`Rate limit against contentful - waiting ${delay}s`);

            return {
              ...operation,
              context: {
                ...operation.context,
                retryDelay: delay * 1000,
              },
            };
          }

          return operation;
        },
      }),
      fetchExchange,
    ],
    requestPolicy: 'cache-and-network',
    fetchOptions: {
      headers: {
        'x-azure-api-secret': process.env.API_SECRET || '',
      },
    },
  });

export const contentfulReq = pipe(
  Do,
  apS('contentful', createClient()),
  of,
  map(({ contentful }) => createContentfulRequest(contentful))
);

export async function contentRequest<T>(
  path: string,
  query?: string,
  locale?: string
): Promise<T> {
  const basePath = `/api/content/v1`;
  const headers: { [key: string]: string } = {
    'x-azure-api-secret': process.env.API_SECRET || '',
  };

  if (locale) {
    headers['x-lng'] = locale;
  }

  const response = await axios({
    baseURL: `${process.env.API_URL}`,
    url: `${basePath}/${path}${query ? `?${query}` : ''}`,
    method: 'get',
    headers,
  });

  if (response.status === 200) {
    return response.data as T;
  }

  throw new Error(
    `Content REST Request unsuccesful. Status: ${response.status} ${response.statusText}`
  );
}

export async function contentfulRest<T>(query?: string): Promise<T> {
  const data = await contentRequest<T>('/contentful/rest', query);
  return data;
}

export const api = axios.create({
  baseURL: `${process.env.API_URL}/api`,
  headers: {
    'x-azure-api-secret': process.env.API_SECRET || '',
  },
});

export const beReq = of(backendRequest(api));
